<template>
  <v-app>
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login form</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="user.uid"
                    prepend-icon="email"
                    placeholder="Enter your email id"
                    clearable
                    required
                  ></v-text-field>
                  <v-text-field
                    type="password"
                    v-model="user.pass"
                    :rules="[v => !!v || 'password required']"
                    prepend-icon="lock"
                    placeholder="Enter your password"
                    clearable
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    required
                  ></v-text-field>
                  <v-btn class="mr-4" @click="clear">Reset</v-btn>
                  <v-btn class="mr-4" color="primary" @click="signIn">Login</v-btn>
                </v-form>
                <br />
                <div class="pull-right">
                  <router-link to="/forgot-password">Forgot Password?</router-link>
                </div>
                <v-col cols="12">
                  <div v-show="message!=null">
                  <v-alert
                    outlined
                    type="error"
                    prominent
                    border="left"
                    dismissible
                  >{{message}}
                  </v-alert>
                  </div>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import axios from "axios";
export default {
  components: {
  },
  data() {
    return {
      user: {
        uid: "",
        pass: ""
      },
    };
  },
  methods: {
    signIn() {
        axios
          .post("/login/process", this.user)
          .then(res => {
            window.console.log("res" + res.data.msg);
            if (res.data.msg == "200") {
              localStorage.setItem("awspath",res.data.awspath)
              localStorage.setItem("EPS-token", res.data.token);
              localStorage.setItem("EPS-uid", res.data.uid);
              this.$router.push("/eps-admin-dashboard");
              //window.location="/home"
            } else {
              this.message = "Please check ur credentials!!!";
              localStorage.setItem("EPS-token", "");
              localStorage.setItem("EPS-uid", "");
            }
          })
          .catch(error => {
            window.console.log(error);
            this.message = "Something went wrong";
          });
    },
    clear() {
      this.user.uid = "";
      this.user.pass = "";
    },
    perormLoginFormValidation() {
      if (this.user.uid == "" && this.user.pass == "") {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.message = localStorage.getItem("login-page-msg");
    console.log("login-page-msg>>"+localStorage.getItem("login-page-msg"));
    localStorage.clear();
  }
};
</script>
</script>
<style scoped>
.pull-right {
  position: relative;
  text-align: center;
}
</style>